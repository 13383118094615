<template>
    <cp-text-field
        ref="input"
        :value="computedValue"
        @input="onInput"
        v-on="listeners"
        v-bind="attrs"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
    >
    </cp-text-field>
</template>

<script>
export default {
    name: 'PasswordInput',
    inheritAttrs: false,
    props: {
        value: String,
    },
    data: vm => ({
        localValue: '',
        showPassword: false,
    }),
    methods: {
        onInput(value) {
            // Retira as mensagens de erro caso o input não formatado tenha algum valor
            // Não alterar o retorna para null por causa da validação
            this.$emit('input', value ? value : '')
        },

        focus() {
            this.$refs.input.focus()
        },
    },
    computed: {
        computedValue() {
            return this.localValue
        },

        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
    watch: {
        value(value) {
            this.localValue = value
        },
    },
}
</script>