var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cp-text-field",
    _vm._g(
      _vm._b(
        {
          ref: "input",
          attrs: {
            value: _vm.computedValue,
            type: _vm.showPassword ? "text" : "password",
            "append-icon": _vm.showPassword ? "mdi-eye" : "mdi-eye-off"
          },
          on: {
            input: _vm.onInput,
            "click:append": function($event) {
              _vm.showPassword = !_vm.showPassword
            }
          }
        },
        "cp-text-field",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }