<template>
    <div v-if="loadingCheck">
        {{ $t('forms.ResetPassword.loading') }}
    </div>
    <div v-else-if="tokenExpired">
        <v-alert type="error">
            {{ $t('forms.ResetPassword.token_expired') }}
        </v-alert>
        <v-btn :to="{ name: 'login' }" text block>{{ $t('actions.back') }}</v-btn>
    </div>
    <v-form v-else @submit.prevent="submit" v-model="isFormValid" lazy-validation="">
        <div class="reset-password-form">

            <PasswordInput
                ref="password_input"
                autofocus
                v-model="formData.password"
                :label="$t('forms.ResetPassword.password.label')"
                :hint="$t('forms.ResetPassword.password.hint')"
                :placeholder="$t('forms.ResetPassword.password.placeholder')"
                :disabled="loading"
                :rules="rules.password"
                @input="clearErrorMessages"
            />

            <PasswordInput
                v-model="formData.password_confirmation"
                :label="$t('forms.ResetPassword.password_confirmation.label')"
                :hint="$t('forms.ResetPassword.password_confirmation.hint')"
                :placeholder="$t('forms.ResetPassword.password_confirmation.placeholder')"
                :disabled="loading"
                :rules="rules.password_confirmation"
                @input="clearErrorMessages"
            />

            <v-alert type="error" v-if="differentPassword">
                {{ $t('forms.ResetPassword.different_password') }}
            </v-alert>

            <v-btn type="submit" color="success" block class="my-3" :disabled="!isFormValid || differentPassword" :loading="loading">{{ $t('actions.update') }}</v-btn>
            <v-btn :to="{ name: 'login' }" text block :disabled="loading">{{ $t('actions.cancel') }}</v-btn>
        </div>
    </v-form>
</template>
<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import PasswordInput from '@/components/inputs/PasswordInput'
import { required, length } from '@/utils/validation'

export default {
    name: 'ResetPassword',
    data: vm => ({
        loading: false,
        loadingCheck: false,
        isFormValid: false,
        tokenExpired: false,
        differentPassword: false,
        formData: {
            password: '',
            password_confirmation: '',
        },
        rules: {
            password: [required.and(length(6))],
            password_confirmation: [required.and(length(6))],
        }
    }),
    components: {
        PasswordInput,
    },
    mixins: [ HasErrorHandlerMixin ],
    async created() {
        await this.verifyToken()
    },
    methods: {
        async verifyToken() {
            this.loadingCheck = true
            let res = await this.$axios.post('/admin/password-reset', this.$route.query)
                .catch(this.preErrorHandler)
            this.loadingCheck = false

            if (!res)
                return
        },

        async submit() {
            this.differentPassword = this.formData.password != this.formData.password_confirmation

            if (!this.isFormValid || this.differentPassword)
                return

            let data = { ...this.$route.query, ...this.formData }

            this.loading = true
            let res = await this.$axios.post('/admin/password-reset', data)
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            this.$router.push({ name: 'login', params: { passwordReset: true } })
            this.$bus.$emit('message', this.$t('forms.ResetPassword.success'), 'success')
        },

        preErrorHandler(e) {
            let status = this.$lodash.get(e, 'response.status')

            if (status == 404) {
                this.tokenExpired = true
                return
            }

            this.errorHandler(e)
        },

        clearErrorMessages() {
            this.differentPassword = false
        },
    },
}
</script>

<style scoped lang="scss">
.reset-password-form {
    max-width: 300px;
    margin: auto;
}
</style>